%step-active {
	color: color('black');

	&:before {
		background: color('orange');
	}

	span {
		color: color('orange');
	}

}

.steps {
	@include span-columns(12);

	ul {

		li {
			@include span-columns(1.5);	
			text-align: center;
			font-size: 16px;
			color: color('gray');

			span {
				font-size: 30px;
				display: block;
				margin: 0 0 10px 0;
			}
			
			&.checkout {
				@include span-columns(9);
				position: relative;

				&:before {
					content: '';
					position: absolute;
					top: 20px;
					left: 0;
					width: 100%;
					height: 1px;
					background: color('gray');
				}

				span {
					background: color('white');
				    position: relative;
				    z-index: 1;
				    width: 100px;
				    margin: 0 auto 10px auto;					
				}

			}

		}

		&.active-cart {

			.cart {
				@extend %step-active;
			}

		}

		&.active-checkout {

			.cart,
			.checkout {
				@extend %step-active;
			}

		}

		&.active-success {

			.cart,
			.checkout,
			.success {
				@extend %step-active;
			}

		}		

	}

}