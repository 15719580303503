.icon-cart {
    font-size: 30px;
    padding: 28px;
    display: block;
    color: color('black');
}

a {
	background: color('orange');
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 90px;

    .count {
    	position: absolute;
    	height: 40px;
    	width: 40px;
    	line-height: 40px;
    	font-size: 16px;
    	text-align: center;
    	background: color('black', 'light');
    	color: color('white');
		top: 28%;
		left: -20px;    	
    }

    .icon-cart {
        color: color('black');
    }

}