.checkout-cart-index {

	.main-container {
    	@include outer-container(100%);
    	
    	.container, .main {
    		@include span-columns(12);
    		padding: 0;
    	}

    	.align-container {
    		@include outer-container();
    		padding: 0 $gapp;
    	}

    }

	.page-title {
		@include span-columns(12);
		margin: 40px 0 10px 0;
		border-bottom: 1px solid rgba(color('gray'), 0.4);
		padding-bottom: 10px;

		h1 {
			@include span-columns(4);
			font-size: 24px;
			color: color('black');
			font-weight: normal;
			padding-top: 10px;
		}

		.buttons {
			@include span-columns(8);
			text-align: right;

			.button {
				display: inline-block;
				width: initial;
				margin-left: 20px;
				font-size: 16px;
			}

		}

	}

	.checkout-buttons {
		@include span-columns(12);
		margin: 20px 0;

		&.bottom {
			margin-bottom: 50px;
		}

		.button {
			@extend %green;
		}

		li {
			@include span-columns(4);

			&:last-child {
				float: right;
			}

		}

	}

	.cart-empty {
		font-size: 16px;
		line-height: 24px;
		min-height: 400px;

		a {
			font-weight: bold;
			color: color('black');
		}

	}

	.crosssell {

		.block-title {
			border-bottom: 1px solid rgba(color('gray'), 0.6);
			padding-bottom: 15px;

			h2 {
				font-size: 24px;
				color: color('black');
				text-transform: uppercase;

			}

		}

		.item {

			text-align: center;

			&:hover {
				@extend %item-hover;
			}
		}
	}


	@import "components/cart/addto.scss";
	@import "components/cart/items.scss";
	@import "components/cart/box.scss";
}