ul {
	display: inline-block;
	font-size: 0;

	li {
		display: inline-block;
		vertical-align: top;
		color: color('gray');
		text-align: center;
		font-size: 24px;
		font-weight: bold;
		line-height: 20px;
		background: color('white');
		border-right: 1px solid color('gray');												

		&:nth-child(odd) {
			background: color('gray', 'light');
		}

		&:first-child {
			display: block;
			text-align: center;
			background: color('orange');
			font-size: 18px;
			color: color('white');
			padding: 15px 0;
			border: 1px solid color('orange');																	
		}

		&:nth-child(2) {
			border-left: 1px solid color('gray');												
		}

		span {
			font-size: 12px;
			font-weight: normal;
			display: block;
			margin-bottom: 5px;

			&.price {
				font-size: 24px;
				font-weight: bold;
			}

		}

		.box-qty,
		.box-price {
			position: relative;
			display: block;			
			padding: 20px 10px;
			border-bottom: 1px solid color('gray');						
		}

		.box-discount {
			padding: 5px 10px;
			border-bottom: 1px solid color('gray');						

			span {
				margin-bottom: 0;
			}

		}

	}

}
