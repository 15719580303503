.sharing-links {
	@include span-columns(2);
	padding-top: 180px;

	li {
		font-size: 40px;
		margin-bottom: 20px;

		a {
			color: color('black');
		}

		.link-facebook {
			
			&:hover {
				color: #3b5998;
			}

		}

		.link-twitter {
			
			&:hover {
				color: #4099FF;
			}

		}		
		
	}

}