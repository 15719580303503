#checkout-review-submit {
    @extend .no-display;
}

#payment_form_webjump_braspag_cc,
#payment_form_webjump_braspag_multi_cccc_one_card,
#payment_form_webjump_braspag_multi_cccc_two_card {
    @include span-columns(12);
    background: url('../images/bg-card.png') no-repeat;
    position: relative;

    li {
        color: color('white');
        margin-right: 0;

        label {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .input-box {
            margin-bottom: 10px;

            input {
                background: color('black');
                border: 0;
                padding: 6px 10px;
                font-size: 14px;
                font-weight: bold;    
                color: color('white');     
            }

            .validation-advice {
                position: absolute;
                right: 30px;
                bottom: 80px
            }

            input, select {

                &.validation-failed {
                    border: 1px solid color('red');
                }  
                                
            }

        }

        &.card-flag {
            position: absolute;
            top: 10px;
            right: 140px;
            width: auto;
        }

        &.card-number {
            margin-top: 60px;
            margin-left: 20px;

            input {
                width: 40%;
            }

            .validation-advice {
                left: 0;
            }

        }

        &.card-name {
            @include span-columns(5);
            margin-left: 20px;
        }

        &.card-exp {
            @include span-columns(2.6);

            .v-fix {
                @include span-columns(4);
                background: color('black');
                text-align: center;
                margin-right: 0;

                &:last-child {
                    @include span-columns(7);
                    float: right;
                }

            }

            select {
                background: color('black');
                border: 0;
                padding: 6px 0 6px 6px;
                font-size: 14px;
                font-weight: bold;    
                color: color('white');   
                -webkit-appearance:none;
                -moz-appearance: none;

                &::-ms-expand {
                    display: none;
                }

            }

        }

        &.card-cvv {
            @include span-columns(2);
            float: right;
            margin-right: 45px !important;
        }

        &.card-installments {
            margin-top: 50px;

            label {
                font-size: initial;
                font-weight: initial;
                color: color('gray', 'dark');
            }

        }

    }

}

#payment_form_webjump_braspag_multi_cccc_one_card,
#payment_form_webjump_braspag_multi_cccc_two_card {

    li {
    
        &.card-total {
            @include span-columns(5);
            margin-left: 20px;
            clear: left;                        
        }
    
    }

}

#payment_form_webjump_braspag_multi_cccc_one_card {

    li {
     
        &.card-number {
            margin-top: 10px;
        }
    
    }

}

#payment_form_webjump_braspag_multi_cccc_two_card {
    margin-top: 50px;
}