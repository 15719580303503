%button-style {
    font-family: $font;
    font-weight: bold;
    padding: 13px 40px;
    letter-spacing: 1px;
    font-size: 20px;
    width: 100%;
    display: block;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    color: color('white');
    background: color('orange');
    cursor: pointer;
    @include transition(background, 0.2s);

    &:hover {
        background: darken(color('orange'), 5);
        color: color('white');
    }

    &.small {
        font-size: 16px;
        text-transform: initial;
        padding: 10px 20px;
    }
    
}

%gray {
    color: color('gray', 'dark');
    background: color('gray', 'semi-light');
}

%gray-hover {
    background: darken(color('gray', 'semi-light'), 5);
}

%green {
    color: color('white');
    background: color('green');
}

%green-hover {
    background: darken(color('green'), 5);
}

%white {
    color: color('orange');
    background: color('white');
}

%white-hover {
    background: lighten(color('white'), 15);
}

%black {
    color: color('white');
    background: color('black');
}

%black-hover {
    background: lighten(color('black'), 15);
}

button,
.button {
    @extend %button-style;

    &.gray {
        @extend %gray;

        &:hover {
            @extend %gray-hover;
        }

    }

    &.green {
        @extend %green;

        &:hover {
            @extend %green-hover;
        }

    }                

    &.white {
        @extend %white;

        &:hover {
            @extend %white-hover;
        }

    }               

    &.black {
        @extend %black;

        &:hover {
            @extend %black-hover;
        }

    }               

}