.breadcrumbs {
	@include span-columns(12);
	padding: 22px 0;

	ul {
		@include outer-container;
		font-size: 16px;

		li {
			display: inline-block;
			vertical-align: top;
			color: color('black');

			a {
				color: color('gray', 'dark');
			}

			.arrow {
				@include arrow($direction:right, $color: color('gray', 'dark'), $width: 3px, $percentage: initial);
				padding: 0 6px;
				font-size: 6px;
				vertical-align: middle;
				display: inline-block;
			}

		}

	}

}