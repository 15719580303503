.add-to {
	@include span-columns(12);
	margin: 40px 0;

	.buybycart-form {
		@include span-columns(8);
		margin-right: 0;

		li {
			@include span-columns(4);

			.field {
				@include span-columns(4);
				float: right;

				&.sku {
					@include span-columns(7);
					float: left;
				}

			}

		}

		.button {
			@include span-columns(4);
			font-size: 16px;
		    padding: 14px 15px;
		    margin-top: 26px;
		    margin-left: 10px;
		}

	}

	.search-product {
		@include span-columns(4);
	}

	.buybycart-suggestion {
		@include span-columns(4);

		ul {
			@include span-columns(12);

			li {
				float: none;
				display: block;
				margin-bottom: 10px;
				cursor: pointer;
				font-size: 16px;

				&:hover {
					font-weight: bold;
					color: color('black');
				}

			}

		}

	}

}