.pages {

	li {
		font-size: 16px;
		display: inline-block;
		vertical-align: middle;
		margin: 0 10px;

		&.current {
			font-size: 22px;
			color: color('orange');
			font-weight: bold;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				bottom: 10px;
				left: 0;
				right: 0;
				width: 100%;
				height: 3px;
				background: color('orange');
			}

		}

		.previous,
		.next {
			color: color('orange');
			text-transform: lowercase;
			position: relative;
			display: block;

			span {
			    font-size: 12px;
			    vertical-align: middle;
			    position: absolute;
			    bottom: 18px;
			}

		}

		.previous {

			span {
				left: -15px;
				transform: rotate(90deg);
			}

		}

		.next {

			span {
				right: -15px;
				transform: rotate(-90deg);
			}

		}
		
	}

}
