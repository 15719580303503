.footer-container {
	@include span-columns(12);
	color: color('white');
	background: color('black');
	background-size: cover;

	.footer {
		@include outer-container;
		padding: 40px $gapp 60px $gapp;

		.links {
			@include span-columns(12);
			padding: 20px 75px 0 75px;
			@import "components/footer/links.scss";
		}

		&.onepage {
			padding: 0 $gapp;
			line-height: 65px;
			@import "components/footer/onepage.scss";
		}

	}

	.copyright {
		@include span-columns(12);
		background: color('white');
		color: color('black');
		@import "components/footer/copyright.scss";
	}

	.payment {

		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			margin-bottom: 10px;
		}

	}

}