.customer-account-login {

	.page-title {
		@include span-columns(12);
		text-align: center;

		h1 {
			color: color('black');
			padding-bottom: 5px;
		}

		p {
			font-size: 16px;
		}

	}

	.customer-form {
		@include span-columns(12);
		margin: 40px 0 120px 0;

		h3 {
			font-weight: bold;
			display: block;
			margin-bottom: 25px;
			font-size: 16px;
			text-transform: uppercase;
			color: color('black');
		}

		.col-1, .col-2 {
			@include span-columns(6, block-collapse);
		}

		.col-1 {
			padding-right: 100px;
			border-right: 2px solid color('black');

			.button {
				@extend %green;
			}

		}
		
		.col-2 {
			padding-left: 98px;

			.button {
				@extend %black;
			}

		}

		.description {
			line-height: 24px;
		}

		.password {

			.input-box {
				margin-bottom: 10px;
			}

		}

		.buttons-set {
			@include span-columns(12);
			margin-top: 40px;
		}

	}

}

.customer-account-logoutsuccess {

	.page-title {
		@include span-columns(12);
		text-align: center;

		h1 {
			color: color('black');
			padding-bottom: 20px;
		}

		p {
			font-size: 16px;

			span {
				font-weight: bold;
				color: color('black');
			}

		}

	}

	.main {
		margin: 50px 0 100px 0;
		min-height: 200px;
	}

}