.product-info {
	@include span-columns(12);
	margin: 0 0 5px 0;

	h1 {
		font-size: 30px;
		font-weight: bold;
		color: color('orange');
		text-transform: initial;
	}

	.sku {
		font-size: 16px;
		padding: 10px 0;
	}

	.author {
		font-size: 20px;

		b {
			font-weight: normal;
			color: color('black');
		}

	}

}

.product-review,
.product-download-pages {
	@include span-columns(6);
    min-height: 34px;
}

.product-review {
    line-height: 30px;	
    padding-top: 10px;
}

.product-download-pages {
	margin-right: 0;

	a {
		text-transform: uppercase;
		font-size: 14px;
	}

}

.price-info {
	@include span-columns(12);
	margin: 20px 0;
	padding: 20px 0;
	border-top: 1px solid color('gray');
	border-bottom: 1px solid color('gray');

	.billet,
	.ccard {
		@include span-columns(6, block-collapse);
		position: relative;
		color: color('black');

		[class*="icon-"] {
			font-size: 20px;
			display: inline-block;
			vertical-align: top;
			color: color('orange');
			position: absolute;
			left: 0;
			top: 4px
		}

		.prices {
			display: inline-block;
			vertical-align: top;	
			padding-left: 40px;

			.price-label {
				@extend .no-display;
			}

			.price {
				font-size: 30px;
				font-weight: bold;
				color: color('orange');
			}

			.old-price {

			    .price {
			        font-size: 14px;
			        font-weight: normal;
			        color: initial;
			        text-decoration: line-through;
			        display: inline-block;
			    }

			}						

		}

		.info {
			font-size: 18px;
			display: block;
			margin-top: 10px;

			b {
				color: color('green');
				font-weight: normal;
			}

		}

	}

}

.block-shipping-estimate,
.add-to-cart-wrapper {
	@include span-columns(6, block-collapse);
	margin-top: 10px;
}

.product-options-bottom,
.add-to-cart-wrapper {
	@include span-columns(6);
	float: right;
	margin-right: 0;
	padding-left: 30px;
	margin-top: 10px;	
}

.add-to-cart-wrapper {

	.button {
		@extend %green;
	}

}

.block-shipping-estimate {
	background: color('gray', 'semi-light');
	color: color('gray', 'dark');
	padding: 25px;

	.title {
		font-size: 16px;
		display: block;
		margin-bottom: 10px;
	}

	input, button {
		font-size: 14px;
		height: 49px;		
	}

	.input-box {
		margin-bottom: 0;
		@include span-columns(7.5, block-collapse);
	
		input {
			padding: 14px 11px;
			font-style: italic;
		}

	}

	button {
		color: color('white');
		padding: 15px 0;
		background: color('gray', 'dark');
		letter-spacing: initial;
		@include span-columns(4.5, block-collapse);
	}

	.shipping-estimate-results {
		@include span-columns(12);

		ul {
			display: block;
			font-size: 16px;

			li {
				padding-top: 15px;
				line-height: 20px;

				&.error-msg {
				    font-size: 14px;
				    line-height: normal;
				    margin-bottom: 10px;
				    padding: 10px 0;
				    margin-top: 15px;
				}

			}

		}

	}

	.please-wait {

		.loader {
			@include span-columns(12);
			margin: 10px 0;
		}

	}

}

.product-options {
	@include span-columns(12);
	font-size: 16px;

	em, p.required, .x {
		@extend .no-display;
	}

	label {
		font-weight: bold;
		display: block;
		margin-bottom: 10px;

		span {
			font-weight: normal;
		}

	}

	.configurable-swatch-list {

		li {
			position: relative;
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			font-size: 14px;
	        height: 30px;
	        line-height: 30px;
	        width: 30px;
	        cursor: pointer;
	        text-align: center;
            background: color('orange');
            @include transition(opacity, 0.2s);
	        @include border-radius(50%, 50%, 50%, 50%);

	        a {
	       		color: color('white');
	        }

	        &.selected {
	        	opacity: 0.5;
	        }

		}

	}

	#configurable_swatch_color {

		li {

			img {
			    display: block;
			    width: 100%;
			    height: 100%;				
				@include border-radius(50%, 50%, 50%, 50%);	
			}

		}

	}

	.options-list {

		.bundle-hide-price {

			label {

				.price-notice {

					display: none;
				}
			}
		}
	}

}

.product-options-bottom {

	.price-box,
	.add-to-links,
	.sharing-links {
		@extend .no-display;
	}

}

.add-to-cart {

	.signature-type {
		@include span-columns(5);

		.input-box {
			margin-bottom: 0;

			&:last-child {
				margin-top: 10px;
			}

		}

		& + .qty {
			@include span-columns(7);
			margin-right: 0;
			float: right;
			margin-top: 0;
		}

	}

	.qty {
		margin-top: 10px;
	}

	.add-to-cart-button {
		@include span-columns(12);
		margin-top: 22px;

		button {
			
			span {
				font-size: 16px;
				padding-right: 5px;
			}

		}

	}

}

.alert-stock {
	@include span-columns(12);
	margin: 10px 0;
	@extend .button;	
	@extend .gray;	
}