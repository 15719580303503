.collateral-tabs {
	@include span-columns(12);
	margin-top: 50px;

	.tabs {
		border-bottom: 1px solid color('gray', 'dark');

		.tab {
			display: inline-block;
			vertical-align: middle;
			font-size: 24px;
			text-transform: uppercase;
			margin-right: 130px;
			padding-bottom: 15px;
			cursor: pointer;
			color: color('gray', 'dark');

			&.active {
				color: color('orange');
				position: relative;

				&:before {
					position: absolute;
					bottom: -2px;
					content: '';
					width: 100%;
					height: 3px;
					background: color('orange');
				}

			}

		}

	}

	.tab-content {
		@include span-columns(12);
		padding: 45px 90px;
		line-height: 20px;
		display: none;
		color: color('black');
	}

}

h2 {
	@extend .no-display;
}

.std {
	line-height: 24px;
}

.product-attributes {

	ul {
		@include span-columns(6);
		@include omega(2n);
		
		li {
			margin-bottom: 30px;
			line-height: 24px;

			strong {
				display: block;
				font-size: 20px;
				font-weight: normal;
				text-transform: uppercase;
			}

		}

	}

}

.box-reviews {

	.heading {
		@include span-columns(12);
		background: color('black');
		padding: 10px 30px;
		color: color('white');
		text-transform: uppercase;

		strong {
			@include span-columns(5);
			font-size: 18px;
			padding: 12px 0;
		}

		span {
			@include span-columns(3);
			font-size: 16px;
			padding: 12px 0;
			text-align: right;
		}

		button {
			@include span-columns(4);
			font-size: 16px;
			letter-spacing: initial;
			color: color('black');
		}

	}

	.reviews {
		@include span-columns(12);

		.item {
			float: left;
			width: 100%;
			padding: 10px 30px;
			border-bottom: 6px solid color('gray', 'light');

			.info {	
				@include span-columns(8);
				padding: 20px 0;

				.title {
					display: block;
					margin-bottom: 15px;
					font-size: 20px;
					color: color('orange');
				}

				.description {
					line-height: 24px;
				}

				.name {
					display: block;
					margin-top: 20px;
					font-size: 16px;

					strong {
						color: color('black');
					}	

				}

			}

			.review {
				@include span-columns(4);
				padding: 25px;
				background: color('gray', 'light');
				font-size: 16px;

				p {
					display: block;
					margin-bottom: 10px;
					font-size: 20px;
					color: color('orange');
				}

				li {
					@include span-columns(12);
					padding: 10px 0;

					.rating-box {
						float: right;
					}

				}

			}

		}

	}

	.more-reviews {
		@include span-columns(12);
		margin-top: 20px;
		text-align: center;

		button {
			font-size: 16px;
			display: block;
			width: 30%;
			margin: 0 auto;
			background: color('black');
		}

	}

}