.catalog-category-view {

	.breadcrumbs {
		padding: 22px $gapp;
	}

	.category-products {
		@include span-columns(12);
		padding-top: 30px;
		margin-top: 15px;
		border-top: 4px solid color('gray', 'light');
	}

	.category-title {
		display: inline-block;
		vertical-align: top;
		padding-left: 20px;

		h1 {
			font-size: 18px;
			font-weight: bold;
			color: color('orange');
			text-transform: initial;
			letter-spacing: 2px;
		}

	}

	.block-layered-nav {
		@import "components/category/layered.scss";
	}

	.toolbar {
		@include span-columns(12);
		@import "components/category/toolbar.scss";
	}

}