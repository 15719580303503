%menu {
	float: left;	
	padding: 0 40px;
	line-height: 52px;
	font-size: 16px;
	margin-left: 80px;

	.ico-menu {
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;

		.ico-menu-hover {
			display: none;
		}

	}

	&:first-child {
		padding-left: 0;
		margin-left: 0;
	}

	&:hover {

		a.level0 {
			color: color('orange');

			.ico-menu {
				
				.hide-hover {
					display: none;
				}

				.show-hover {
					display: block;
				}

			}

		}	
		
	}

}

%sub-menu-container {
	@include arrow($color: color('orange'), $align: left, $percentage: 45px);
	@include box-shadow();	
	@include fade();
	background: color('white');
	border-top: 1px solid color('orange');
	position: absolute;
	z-index: 10;
	text-align: left;
	margin-left: -40px;
	line-height: normal;

	&.has-banner {

		ul {
			padding-right: 0;
			padding-bottom: 0;

			&.promo-banner {
				display: inline-block;
				padding: 0;
				padding-left: 0;
			}

		}

	}

}

%sub-menu {
	font-size: 18px;
	padding-bottom: 18px;

	&:hover {

		a.level1 {
			color: color('orange');
		}	
		
	}

}

.nav-primary {
	@include outer-container;
	padding-left: $gapp;
	position: relative;

	li {
		
		&.level0 {
			@extend %menu;
		}

		ul {

			&.level0 {
				@extend %sub-menu-container;

				ul {
					display: inline-block;
					vertical-align: top;
					padding: 40px 45px;

					li {

						&:last-child {
							padding-bottom: 0;
						}

					}

				}

			}

			li {

				&.level1 {
					@extend %sub-menu;
				}

			}

		}

        &.menu-active {

            & > .level0 {
                @include fade($type: in);
            }

        }		

	}

}