.cart-items-table {

	th, td {

		&.product {
			width: 30%;
		}

		&.product-price,
		&.product-total,
		&.product-discount,
		&.product-discount-price {
			width: 11%;
			text-align: center;
		}

		&.product-qty {
			width: 20%;
			text-align: center;
		}

		&.product-remove {
			text-align: right;
		}

	}

} 

.cart-items-title {

	th {
		padding: 20px 0 15px 0;
		font-weight: bold;
		color: color('orange');
		text-transform: uppercase;
		letter-spacing: 2px;
	}

}

.cart-items {
	background: color('gray', 'light');
	margin-bottom: 30px;

	tr {

		td {
			padding: 20px 0;
			border-bottom: 1px solid color('gray');

			&.product {

				.product-image,
				.product-info {
					display: inline-block;
					vertical-align: middle;
				}

				.product-image {
					margin-right: 10px;
				}

				.product-info {
					
					.product-name {
						font-size: 16px;
						margin-bottom: 10px;
					}

					.product-sku {
						font-size: 13px;
						color: color('gray', 'dark');
					}

				}

			}

			&.product-price,
			&.product-total,
			&.product-discount,
			&.product-discount-price {
				font-size: 20px;
				color: color('gray', 'dark');
			}

			&.product-remove {
				font-size: 12px;
				text-transform: uppercase;
				
				a {
					color: color('gray', 'dark');

					span {
						position: relative;
						top: 2px;
						font-size: 18px;
						margin-right: 5px;
					}

				}

			}

			.item-msg {
				@include span-columns(12);
				margin: 10px 0 0 0;
				line-height: 20px;

				&.error {
					color: color('red');
				}

			}

		}

		&:last-child {

			td {
				border-bottom: 0;
			}
			
		}

	}

}	