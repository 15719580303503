.cms-no-route {

	.col-main {
		position: relative;
	}

	.page-title, .std {
		@include span-columns(7);
		float: right;
		padding: 20px 100px;
	}

	.page-title {
		border-bottom: 4px solid color('gray', 'light');

		h1 {
			padding-bottom: 0;
			color: color('black');
		}

	}

	.std { 
		font-size: 16px;
		line-height: 24px;
		padding-right: 20px;

		#search_mini_form {
			@include span-columns(12);
			margin: 30px 0 10px 0;

			.input-box {
				@include span-columns(10);
				position: relative;

				.search-button {
				    position: absolute;
				    right: 5px;
				    top: 5px;
				    width: 40px;
				    height: 37px;
				    padding: 0;
				    line-height: 42px;
				    background: color('black');
				}

			}

		}

		a {
			text-decoration: underline;
		}

		img {
			position: absolute;
			left: 0;
			top: 100px;
		}

	}

	.tabs-home {
		@include span-columns(12);
		position: relative;
		margin-top: 80px;

		.tabs-block {
			padding: 0 0 15px 0;
			text-transform: uppercase;
			color: color('black', 'light');
			border-bottom: 1px solid rgba(color('gray'), 0.2);

			h2 {
				font-size: 24px;					
			}

		}

		.loader {
			margin: 20px auto;
		}

	}

}